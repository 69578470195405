import { model as router } from '!/router'
import { model as player } from '$/player'
import { model as config, remote } from '@/config'
import { OPEN_FROM, PATH } from '@/constants'
import { api, ContentType } from '@setplex/tria-api'
import {
  createEvent,
  createStore,
  sample,
  type EventCallable,
  type Store,
  type StoreWritable,
} from 'effector'
import { and } from 'patronum'
import { appVersion, runtime } from '~/app'
import { model as launchModel } from '~/core/launch'
import { model as sessionEntityModel } from '~/entities/session'
import { preferences } from '~/features/preferences'
import { session } from '~/features/session'
import { $route } from '~/processes/navigation'
import { postpone } from '~/shared/effector'
import { aye, getIdFromUrl, T } from '~/shared/helpers'
import * as signInModel from '~/widgets/auth/sign-in/model'
import * as signUpModel from '~/widgets/auth/sign-up/model'
import { welcome } from '~/widgets/auth/welcome'
import { epgInfoWidgetModel } from '~/widgets/epg/info'
import { type IParamsPageOpenEvent } from './index.h'
import { getParamsFromUrl } from './lib'

export const init: EventCallable<void> = createEvent()

export const $domain: Store<string> = config.get(
  remote.uvo_analyticsListenerDomain
)

export const $enabledAnalytics: StoreWritable<boolean> = createStore(false)

sample({
  clock: config.$ready,
  source: $domain,
  filter: aye,
  fn: T,
  target: $enabledAnalytics,
})

// get domain for analytics
sample({
  clock: $enabledAnalytics,
  source: $domain,
  filter: (domain, enabledAnalytics) =>
    Boolean(domain) && Boolean(enabledAnalytics),
  fn: (domain) => domain,
  target: api.analytics_ovp.$analyticsDomain,
})

export const collectApplicationInfo: EventCallable<void> = createEvent()

// on init -> collect application info and start session
sample({
  clock: init,
  target: collectApplicationInfo,
})

// TODO: seems can be refactor to clock: init and remove collectApplicationInfo event
sample({
  clock: collectApplicationInfo,
  fn: () => ({
    appId: runtime.app.domain,
    appName: runtime.app.name,
    appVersion: appVersion,
    browserName:
      typeof runtime.browser.name === 'string'
        ? runtime.browser.name || 'Unknown'
        : JSON.stringify(runtime.browser.name),
    browserVersion: runtime.browser.version || '-',
    osName: runtime.os.name || 'Unknown',
    osVersion: runtime.os.version || '-',
    userAgent: runtime.ua.ua || '',
    contentProviderId: runtime.isBot ? 'bot' : 'uvo',
  }),
  target: api.analytics_ovp.$applicationInfo,
})

export const $readyAllInfo = createStore<boolean>(false)
export const awaited: EventCallable<void> = createEvent()

// Event can be sent after all data ready and analytics enabled
export const $eventCanBeSent = and($readyAllInfo, $enabledAnalytics)

// await for data from BE sessionEntityModel.$info and /launch request
postpone({
  clock: session.updateAllSessionInfo,
  until: $readyAllInfo,
  target: awaited,
})

sample({
  clock: [
    sessionEntityModel.$info,
    config.$ready,
    launchModel.$isLaunchDataReady,
  ],
  source: {
    info: sessionEntityModel.$info,
    configReady: config.$ready,
    isLaunchDataReady: launchModel.$isLaunchDataReady,
  },
  filter: ({ info, configReady, isLaunchDataReady }) =>
    Boolean(info) && Boolean(configReady) && Boolean(isLaunchDataReady),
  fn: T,
  target: $readyAllInfo,
})

sample({
  clock: sessionEntityModel.$info,
  target: api.analytics_ovp.$userInfo,
})

// when BE data and /launch is ready -> send `session_start` analytics event
sample({
  clock: awaited,
  source: launchModel.$customErrorCode,
  filter: $enabledAnalytics,
  fn: (customErrorCode) => ({ customErrorCode }),
  target: api.analytics_ovp.sendAnalyticsSessionStart,
})

export const setParamsForPageOpenEvent: EventCallable<IParamsPageOpenEvent> =
  createEvent()
export const $paramsPageOpenEvent = createStore<null | IParamsPageOpenEvent>(
  null
).on(setParamsForPageOpenEvent, (_, payload) => payload)

// Pages for which you need to send additional params with data from BE
const $isNeedAdditionalPageOpenParams = $route.map((route) => {
  const routeName = route?.routeName || ''
  return (
    routeName === PATH.CREATOR ||
    routeName === PATH.MOVIE ||
    (routeName.includes(PATH.LANGUAGE) && routeName !== PATH.LANGUAGES) ||
    routeName.includes(PATH.CATEGORY) ||
    routeName.includes(PATH.PLAY) ||
    routeName === PATH.HASHTAG
  )
})

// Send `page_open` analytics event
// Also send event when the user closed the popup (means 'went to another page')
// exclude pages /creator, /movie, /movie/play, live/play, /category, /language, /hashtag
sample({
  clock: [
    $eventCanBeSent,
    $route,
    signInModel.$isOpenedSignInPopup,
    signUpModel.$isOpenedSignUpPopup,
    welcome.$isWelcomePopupOpened,
    preferences.$isPreferencesPopupOpened,
  ],
  source: {
    eventCanBeSent: $eventCanBeSent,
    route: $route,
    isSignInPopupOpened: signInModel.$isOpenedSignInPopup,
    isSignUpPopupOpened: signUpModel.$isOpenedSignUpPopup,
    isWelcomePopupOpened: welcome.$isWelcomePopupOpened,
    isPreferencesPopupOpened: preferences.$isPreferencesPopupOpened,
    isNeedAdditionalPageOpenParams: $isNeedAdditionalPageOpenParams,
  },
  filter: ({
    eventCanBeSent,
    route,
    isSignInPopupOpened,
    isSignUpPopupOpened,
    isWelcomePopupOpened,
    isPreferencesPopupOpened,
    isNeedAdditionalPageOpenParams,
  }) => {
    return isNeedAdditionalPageOpenParams
      ? false
      : Boolean(eventCanBeSent) &&
          Boolean(route) &&
          !isSignInPopupOpened &&
          !isSignUpPopupOpened &&
          !isWelcomePopupOpened &&
          !isPreferencesPopupOpened
  },
  fn: ({ route }) => {
    const entityId = getIdFromUrl(route?.props?.titleWithId)
    const path = route?.routeName || ''
    const params = getParamsFromUrl()

    return {
      path,
      entityId: String(entityId ?? ''),
      ...params,
    }
  },
  target: api.analytics_ovp.sendAnalyticsPageOpen,
})

// Send 'page_open' event for pages
// where you need to wait data from BE for event params
sample({
  clock: [
    $eventCanBeSent,
    signInModel.$isOpenedSignInPopup,
    signUpModel.$isOpenedSignUpPopup,
    welcome.$isWelcomePopupOpened,
    preferences.$isPreferencesPopupOpened,
    $paramsPageOpenEvent,
  ],
  source: {
    eventCanBeSent: $eventCanBeSent,
    route: $route,
    isSignInPopupOpened: signInModel.$isOpenedSignInPopup,
    isSignUpPopupOpened: signUpModel.$isOpenedSignUpPopup,
    isWelcomePopupOpened: welcome.$isWelcomePopupOpened,
    isPreferencesPopupOpened: preferences.$isPreferencesPopupOpened,
    paramsPageOpenEvent: $paramsPageOpenEvent,
    isNeedAdditionalPageOpenParams: $isNeedAdditionalPageOpenParams,
    prevRoute: router.$previous,
  },
  filter: ({
    eventCanBeSent,
    route,
    isSignInPopupOpened,
    isSignUpPopupOpened,
    isWelcomePopupOpened,
    isPreferencesPopupOpened,
    paramsPageOpenEvent,
    isNeedAdditionalPageOpenParams,
  }) => {
    // isNeedAdditionalPageOpenParams also filter not to send previous paramsPageOpenEvent after any popup was opened and closed
    return (
      isNeedAdditionalPageOpenParams &&
      Boolean(eventCanBeSent) &&
      Boolean(route) &&
      !isSignInPopupOpened &&
      !isSignUpPopupOpened &&
      !isWelcomePopupOpened &&
      !isPreferencesPopupOpened &&
      Boolean(paramsPageOpenEvent)
    )
  },
  fn: ({ route, paramsPageOpenEvent, prevRoute }) => {
    const entityId = getIdFromUrl(route?.props?.titleWithId)
    const tab = paramsPageOpenEvent?.activeTab || ''
    let path = route?.routeName || ''
    const params = getParamsFromUrl()

    // case if navigate from Movies module to category page
    if (path === PATH.CATEGORY && prevRoute?.pathname === PATH.MOVIES) {
      path = `${PATH.MOVIES}${PATH.CATEGORY}`
    } else {
      path = tab ? `${path}/${tab}` : path
    }

    return {
      path,
      entityId: String(entityId ?? ''),
      creatorId: String(paramsPageOpenEvent?.creatorId ?? ''),
      contentTitle: paramsPageOpenEvent?.contentTitle ?? '',
      ...params,
    }
  },
  target: api.analytics_ovp.sendAnalyticsPageOpen,
})

// Send `page_open` analytics event if sign in or sign up popup is opened
// Popup is treated as a separate page at present time
sample({
  clock: [
    signInModel.$isOpenedSignInPopup,
    signUpModel.$isOpenedSignUpPopup,
    welcome.$isWelcomePopupOpened,
    preferences.$isPreferencesPopupOpened,
  ],
  source: {
    eventCanBeSent: $eventCanBeSent,
    isSignInPopupOpened: signInModel.$isOpenedSignInPopup,
    isSignUpPopupOpened: signUpModel.$isOpenedSignUpPopup,
    isWelcomePopupOpened: welcome.$isWelcomePopupOpened,
    isPreferencesPopupOpened: preferences.$isPreferencesPopupOpened,
  },
  filter: ({
    eventCanBeSent,
    isSignInPopupOpened,
    isSignUpPopupOpened,
    isWelcomePopupOpened,
    isPreferencesPopupOpened,
  }) =>
    (Boolean(eventCanBeSent) &&
      (Boolean(isSignInPopupOpened) ||
        Boolean(isSignUpPopupOpened) ||
        Boolean(isWelcomePopupOpened))) ||
    Boolean(isPreferencesPopupOpened),
  fn: ({
    isSignInPopupOpened,
    isSignUpPopupOpened,
    isWelcomePopupOpened,
    isPreferencesPopupOpened,
  }) => {
    let path = ''

    if (isSignInPopupOpened) {
      path = PATH.SIGN_IN
    }
    if (isSignUpPopupOpened) {
      path = PATH.SIGN_UP
    }
    if (isWelcomePopupOpened) {
      path = `${PATH.SIGN_IN}/profile`
    }
    if (isPreferencesPopupOpened) {
      path = '/preferences'
    }

    return {
      path,
    }
  },
  target: api.analytics_ovp.sendAnalyticsPageOpen,
})

// send `play_start` analytics event
// after that `player.$playerData` shoud be reseted to avoid sending requests with every `epgInfoWidgetModel.$playing` changes
sample({
  clock: [player.$playerData, epgInfoWidgetModel.$playing],
  source: {
    playerData: player.$playerData,
    epgData: epgInfoWidgetModel.$playing,
    enabledAnalytics: $enabledAnalytics,
    playbackTrigger: player.$playbackTrigerData,
  },
  filter: ({ playerData, epgData, enabledAnalytics }) => {
    if (playerData?.contentType === ContentType.live) {
      return (
        Boolean(playerData) && Boolean(epgData) && Boolean(enabledAnalytics)
      )
    } else {
      return Boolean(playerData) && Boolean(enabledAnalytics)
    }
  },
  fn: ({ playerData, epgData, playbackTrigger }) => {
    const { contentType } = playerData || {}
    //data depends on contentType
    const dataToSend = {
      ...playerData,
      playbackStartTrigger: playbackTrigger || '',
    }
    return contentType === ContentType.live
      ? { ...dataToSend, ...epgData }
      : dataToSend
  },
  target: [
    api.analytics_ovp.sendAnalyticsStartWatching,
    player.resetPlayerData,
  ],
})

// send `play_stop` analytics event
sample({
  clock: player.stop,
  source: {
    epgDataPrev: epgInfoWidgetModel.$prevPlaying,
    epgData: epgInfoWidgetModel.$playing,
    enabledAnalytics: $enabledAnalytics,
  },
  filter: ({ epgData, epgDataPrev, enabledAnalytics }, playerData) => {
    // if switch channels `epgData` will be equal `null` so we will use previous state of store
    // it will be used `epgData` if close player by navigating back or by clicking close button
    const epgToSend = epgDataPrev || epgData
    if (playerData?.contentType === ContentType.live) {
      return Boolean(epgToSend) && Boolean(enabledAnalytics)
    } else {
      return Boolean(enabledAnalytics)
    }
  },
  fn: ({ epgData, epgDataPrev }, playerData) => {
    if (playerData?.contentType === ContentType.live) {
      const epgToSend = epgDataPrev || epgData
      return {
        ...playerData,
        ...epgToSend,
      }
    } else {
      return playerData
    }
  },
  target: api.analytics_ovp.sendAnalyticsStopPauseWatching,
})

// send `player_open` analytics event
sample({
  clock: player.watchingContent,
  filter: $enabledAnalytics,
  target: api.analytics_ovp.sendAnalyticsPlayerOpen,
})

// Firebase flag value
export const $playerWatchingEventInterval: Store<number | undefined> =
  config.get(remote.uvo_playerWatchingEventInterval)

// send `player_watching` analytics event
sample({
  clock: player.playerWatching,
  source: {
    playerWatchingEventInterval: $playerWatchingEventInterval,
    enabledAnalytics: $enabledAnalytics,
    epgData: epgInfoWidgetModel.$playing,
  },
  filter: (
    { playerWatchingEventInterval, enabledAnalytics, epgData },
    playerData
  ) => {
    if (playerData?.contentType === ContentType.live) {
      return (
        Boolean(epgData) &&
        Boolean(enabledAnalytics) &&
        Boolean(playerWatchingEventInterval)
      )
    } else {
      return Boolean(playerWatchingEventInterval) && enabledAnalytics
    }
  },
  fn: ({ epgData }, playerData) => {
    if (playerData?.contentType === ContentType.live) {
      return {
        ...playerData,
        ...epgData,
      }
    } else {
      return playerData
    }
  },
  target: api.analytics_ovp.sendAnalyticsPlayerWatching,
})

// send `player_event` analytics event
sample({
  clock: player.playerEvent,
  source: {
    epgData: epgInfoWidgetModel.$playing,
    enabledAnalytics: $enabledAnalytics,
  },
  filter: ({ epgData, enabledAnalytics }, playerData) => {
    if (playerData?.contentType === ContentType.live) {
      return Boolean(epgData) && Boolean(enabledAnalytics)
    } else {
      return Boolean(enabledAnalytics)
    }
  },
  fn: ({ epgData }, playerData) => {
    if (playerData?.contentType === ContentType.live) {
      return {
        ...playerData,
        ...epgData,
      }
    } else {
      return playerData
    }
  },
  target: api.analytics_ovp.sendAnalyticsPlayerEvent,
})

// Collect data from routers
export const setRouterData = createEvent<string>()

export const $routerData = createStore<string>(OPEN_FROM.DIRECT_LINK).on(
  setRouterData,
  (_, data) => data
)

// Send 'epg_view' event for pages
sample({
  clock: player.sendEpgViewEventData,
  source: {
    epgViewEventData: player.$epgViewEventData,
    enabledAnalytics: $enabledAnalytics,
  },
  filter: ({ epgViewEventData, enabledAnalytics }) =>
    Boolean(epgViewEventData) && Boolean(enabledAnalytics),
  fn: ({ epgViewEventData }) => {
    if (!epgViewEventData) return {}
    return epgViewEventData
  },
  target: api.analytics_ovp.sendAnalyticsEpgView,
})
